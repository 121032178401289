import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs/Subscription';
import {
  FormGroup,
  FormBuilder,
  Validators,
  MinLengthValidator
} from '@angular/forms';
import { WizardService } from '../../../../service/wizard/wizard.service';
import { CadastroService } from '../../../../service/cadastro/cadastro.service';
import { ConsultaUsuarioService } from '../../../../service/consulta-usuario/consultar.user.service';

import { TranslateService } from '@ngx-translate/core';
import { PatternsInterface } from 'src/app/patterns/patterns.interface';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../utils/swalObject';

@Component({
  selector: 'app-representante-legal',
  templateUrl: 'representante-legal.component.html',
  styleUrls: ['./representante-legal.component.scss']
})
export class RepresentanteLegalComponent implements OnInit {
  routeSub: Subscription;

  index = 3;
  repLegalForm: FormGroup;
  outorgouForm: FormGroup;
  loading: boolean = false;
  outorgou = 'não';
  outorgouProcuracao: boolean;
  disabled: boolean = true;
  pessoa: any;

  perfil = '';
  data = {};

  procuracao: any = '';
  contratoSocial: any = '';
  nomeArquivoContrato: string = 'Escolher arquivo...';
  nomeArquivoProcuracao: string = 'Escolher arquivo...';

  personalidadeJuridica: any;
  setorAtuacao: any;
  prefeitura: any;

  labels: any;
  celMask = new PatternsInterface().cel;


  public semMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public menasMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  public cpfMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ];
  public cnpjMask = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ];

  constructor(
    private wizardService: WizardService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private cadastroService: CadastroService,
    private consultaService: ConsultaUsuarioService,
    private consultaUsuario: ConsultaUsuarioService,
    private location: Location,
    private translate: TranslateService
  ) {
    this.wizardService.updateIndex(this.index);
    this.outorgouProcuracao = false;
    this.setLabels();
  }

  ngOnInit() {
    const storedData = localStorage.getItem('data');
    if (storedData) {
      this.data = JSON.parse(storedData);
      const storedPerfil = localStorage.getItem('perfil');
      const storedTipo = localStorage.getItem('tipo');
      if (storedPerfil) {
        this.data['perfil'] = storedPerfil;
      }
      if (storedTipo) {
        this.data['tipo'] = storedTipo;
        this.pessoa = storedTipo;
      }
      this.wizardService.userData = this.data;
      if ((this.data as any).prefeituras && ((this.data as any).prefeituras.length > 0)) {
        this.prefeitura = (this.data as any).prefeituras[0];
      } else {
        const storedPrefeitura = localStorage.getItem('prefeitura');
        this.prefeitura = storedPrefeitura ? JSON.parse(storedPrefeitura) : { configuracao: {} };
      }
    } else {
      if (this.wizardService.userData) {
        this.data = this.wizardService.userData;
        if ((this.wizardService.userData as any).prefeituras && ((this.wizardService.userData as any).prefeituras.length > 0)) {
          this.prefeitura = (this.wizardService.userData as any).prefeituras[0];
        }
      }
    }
  
    this.perfil = this.wizardService.userData && this.wizardService.userData.perfil ? this.wizardService.userData.perfil : '';
  
    if (
      !this.wizardService.userData.representanteLegal ||
      (Object.keys(this.wizardService.userData.representanteLegal).length === 0 &&
       this.wizardService.userData.representanteLegal.constructor === Object)
    ) {
      this.criarFormulario();
    } else {
      this.edit();
    }
  
    this.personalidadesJuridicasativos();
    this.setorAtuacaosativos();
  }  

  async edit() {
    let criaFormulario = await this.criarFormulario();
    this.updateForm();
  }

  criarFormulario() {
    this.repLegalForm = this.fb.group({
      cpfRepresentanteLegal: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(18),
          Validators.minLength(14)
        ])
      ],
      nomeRepresentanteLegal: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(160)])
      ],
      celularRepresentanteLegal: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(15)])
      ],
      emailRepresentanteLegal: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(60)])
      ],
      comboSetorAtuacao: [
        '',
        Validators.required
      ],
      comboPersonalidadeJuridica: [
        '',
        Validators.required
      ],
      personalidadeJuridicaRepresentante: { codigo: ''},
      setorAtuacaoRepresentante: { codigo: '' },
    });

    this.outorgouForm = this.fb.group({
      cpfProcurador: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(14),
          Validators.minLength(14)
        ])
      ],
      nomeProcurador: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(160)])
      ],
      celularProcurador: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(15)])
      ],
      emailProcurador: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(60)])
      ]
    });

    this.watchRepLegalForm();
    this.watchOutorgouForm();
    
    if(this.prefeitura.configuracao.exigeCertificadoCnpj === 0){
      this.repLegalForm.controls.comboSetorAtuacao.setValidators([]);

      this.repLegalForm.controls.comboPersonalidadeJuridica.setValidators([]);

    }

  }

  consultarRepresentante(id) {
    if (this.repLegalForm.controls.cpfRepresentanteLegal.valid) {
      this.loading = true;
      this.consultaService.consultarUsuario(id).subscribe(
        res => {
          this.loading = false;
          let data = res.resultado;
          // if (data.situacao === 0) {
          // }
          this.repLegalForm.patchValue({
            // cpfRepresentanteLegal: data.cpfCnpj,
            nomeRepresentanteLegal: data.nomeRazaoSocial,
            celularRepresentanteLegal:
              data.ddd && data.celular ? data.ddd.concat(data.celular) : '',
            emailRepresentanteLegal: data.email,
          });
          if(data.detalhe.personalidadeJuridica !== null && data.detalhe.setorAtuacao !== null){
            this.repLegalForm.patchValue({
              comboPersonalidadeJuridica: data.detalhe.personalidadeJuridica.codigo,
              comboSetorAtuacao: data.detalhe.setorAtuacao.codigo
            })
          }
          let obj = this.repLegalForm.value;
          for (let key in obj) {
            if (key !== 'cpfRepresentanteLegal') {
              this.repLegalForm.controls[key].disable();
            }
          }
          this.repLegalForm.controls['cpfRepresentanteLegal'].setErrors(null);

          this.validateForm();
        },
        error => {
          console.log('error: ', error);
          this.loading = false;
          // this.watchRepLegalForm();

          if (error.error.excecao.codigo === 'E000') {
            Swal.fire(
              getSwal(
                'error',
                '',
                error.error.excecao.recomendacao,
                'ok',
                null
              )
            )
            this.repLegalForm.controls['cpfRepresentanteLegal'].setErrors({
              incorrect: true
            });
          } else {
            this.repLegalForm.controls['cpfRepresentanteLegal'].setErrors(null);
          }

          this.repLegalForm.controls['nomeRepresentanteLegal'].enable();
          this.repLegalForm.controls['celularRepresentanteLegal'].enable();
          this.repLegalForm.controls['emailRepresentanteLegal'].enable();

          this.validateForm();
        }
      );
    }
  }

  consultarProcurador(id) {
    this.loading = true;
    this.consultaService.consultarUsuario(id).subscribe(
      res => {
        this.loading = false;
        let data = res.resultado;
        // if (data.situacao === 0) {
        // }
        this.outorgouForm.patchValue({
          cpfProcurador: data.cpfCnpj,
          nomeProcurador: data.nomeRazaoSocial,
          celularProcurador: data.ddd.concat(data.celular),
          emailProcurador: data.email
        });

        let obj = this.outorgouForm.value;
        for (let key in obj) {
          if (key !== 'cpfProcurador') {
            this.outorgouForm.controls[key].disable();
          }
        }
        this.outorgouForm.controls['cpfProcurador'].setErrors(null);

        this.validateForm();
      },
      error => {
        console.log('error: ', error);

        this.loading = false;

        if (error.error.excecao.codigo === 'E000') {
          this.outorgouForm.controls['cpfProcurador'].setErrors({
            incorrect: true
          });
        } else {
          this.outorgouForm.controls['cpfProcurador'].setErrors(null);
        }

        this.outorgouForm.controls['nomeProcurador'].enable();
        this.outorgouForm.controls['celularProcurador'].enable();
        this.outorgouForm.controls['emailProcurador'].enable();

        this.validateForm();
      }
    );
  }

  updateForm() {
    let representanteLegal = this.wizardService.userData.representanteLegal;
    if (representanteLegal.nomeArquivoContrato) {
      this.nomeArquivoContrato = representanteLegal.nomeArquivoContrato;
    }

    for (let key in representanteLegal) {
      if (
        key === 'celularRepresentanteLegal' &&
        representanteLegal['dddCelularRepresentanteLegal']
      ) {
        let celularRep = representanteLegal[
          'dddCelularRepresentanteLegal'
        ].replace(/\D+/g, '').concat(representanteLegal['celularRepresentanteLegal'].replace(/\D+/g, ''));
        this.repLegalForm.controls['celularRepresentanteLegal'].patchValue(
          celularRep
        );
      } else if (key === 'nomeArquivoContrato') {
        this.nomeArquivoContrato = representanteLegal[key];
      } else if (key === 'contratoSocial') {
        this.contratoSocial = representanteLegal[key];
      } else if (this.repLegalForm.controls[key] && representanteLegal[key]) {
        this.repLegalForm.controls[key].patchValue(representanteLegal[key]);
        } else {
      console.log(' elementos não encontrados :: ', key);
      }
    }

    if (representanteLegal['outorgouProcuracao'] === true) {
      this.outorgou = 'sim';
      let procurador = this.wizardService.userData.procurador;

      if (procurador.nomeArquivoProcuracao) {
        this.nomeArquivoProcuracao = procurador.nomeArquivoProcuracao;
      }

      for (let key in procurador) {
        if (key === 'celularProcurador') {
          let telefone =
            procurador['dddCelularProcurador'] &&
            procurador['celularProcurador']
              ? procurador['dddCelularProcurador'].concat(
                  procurador['celularProcurador']
                )
              : '';
          this.outorgouForm.controls['celularProcurador'].patchValue(telefone);
        } else if (key === 'dddCelularProcurador') {
        } else if (key === 'nomeArquivoProcuracao') {
          this.nomeArquivoProcuracao = procurador[key];
        } else if (key === 'procuracao') {
          this.procuracao = procurador[key];
        } else {
          console.log('key::', key);
          this.outorgouForm.controls[key].patchValue(procurador[key]);
        }
      }
    } else {
      this.outorgou = 'não';
    }
    this.outorgouProcuracao = this.wizardService.userData.representanteLegal[
      'outorgouProcuracao'
    ];
    this.validateForm();
  }

  watchRepLegalForm(): void {
    this.repLegalForm.valueChanges.subscribe(val => {
      // console.log('watchRepLegalForm', val);
      this.validateForm();
      // if (this.outorgou === 'não' && this.repLegalForm.valid) {
      //   this.disabled = false;
      // } else {
      //   this.disabled = true;
      // }
    });
  }

  watchOutorgouForm(): void {
    this.outorgouForm.valueChanges.subscribe(val => {
      this.validateForm();
      // console.log('watchOutorgouForm', val);
      // if (this.repLegalForm.valid && this.outorgouForm.valid) {
      //   this.disabled = false;
      // } else {
      //   this.disabled = true;
      // }
    });
  }

  validateForm() {
    if (
      (this.outorgou === 'não' && this.repLegalForm.valid) ||
      (this.repLegalForm.valid && this.outorgouForm.valid)
    ) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  handleChange(evt) {
    // console.log(evt.target, this.outorgou, this.repLegalForm.valid);
    if (evt.target.value === 'sim') {
      this.outorgouProcuracao = true;
      if (!this.outorgouForm.valid) {
        this.disabled = true;
      }
    } else {
      this.outorgouProcuracao = false;
      if (this.repLegalForm.valid) {
        this.disabled = false;
      }
    }
  }

  arquivoContratoSocial(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.nomeArquivoContrato = event.target.files[0].name;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.contratoSocial = reader.result.toString().split(',').pop();
      };
    }
  }

  arquivoProcuracao(event) {
    console.log('arquivo procuração ::');
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.nomeArquivoProcuracao = event.target.files[0].name;
      reader.readAsDataURL(file);
      console.log(reader);
      reader.onload = () => {
        this.procuracao = reader.result.toString().split(',').pop();
      };
    }
  }

  async proximo() {
    this.wizardService.userData.representanteLegal = await this.formatarDadosRepLegal();
    if(this.prefeitura.configuracao.exigeCertificadoCnpj==0){
      this.wizardService.userData.representanteLegal[
        'personalidadeJuridicaRepresentante'
      ].codigo = "0"
      this.wizardService.userData.representanteLegal[
        'setorAtuacaoRepresentante'
      ].codigo = "0"
    } else{
      this.wizardService.userData.representanteLegal[
        'personalidadeJuridicaRepresentante'
      ].codigo = this.wizardService.userData.representanteLegal.comboPersonalidadeJuridica;
      this.wizardService.userData.representanteLegal[
        'setorAtuacaoRepresentante'
      ].codigo = this.wizardService.userData.representanteLegal.comboSetorAtuacao;
    }
    this.wizardService.userData.representanteLegal[
      'outorgouProcuracao'
    ] = this.outorgouProcuracao;
    if (this.outorgouProcuracao) {
      this.wizardService.userData.procurador = await this.formatarDadosProcurador();
    } else {
      this.wizardService.userData.procurador = {};
    }
    console.log(' >>> gfenerate user: ', this.wizardService.userData);
    localStorage.setItem('data', JSON.stringify(this.wizardService.userData));
    this.router.navigate(['cadastro', this.perfil, 'resumo']);
  }

  formatarDadosProcurador() {
    let dataProcurador = this.outorgouForm.getRawValue();
    if (this.procuracao !== '') {
      dataProcurador['procuracao'] = this.procuracao;
      dataProcurador['nomeArquivoProcuracao'] = this.nomeArquivoProcuracao;
    }
    dataProcurador['dddCelularProcurador'] = dataProcurador[
      'celularProcurador'
    ].substring(0, 2);
    dataProcurador['celularProcurador'] = dataProcurador[
      'celularProcurador'
    ].substr(2, 9);
    return dataProcurador;
  }

  formatarDadosRepLegal() {
    let dataRepLegal = this.repLegalForm.getRawValue();
    if (this.contratoSocial !== '') {
      dataRepLegal['contratoSocial'] = this.contratoSocial;
      dataRepLegal['nomeArquivoContrato'] = this.nomeArquivoContrato;
    }
    dataRepLegal['dddCelularRepresentanteLegal'] = dataRepLegal[
      'celularRepresentanteLegal'
    ].replace(/\D+/g, '').substring(0, 2);
    dataRepLegal['celularRepresentanteLegal'] = dataRepLegal[
      'celularRepresentanteLegal'
    ].replace(/\D+/g, '').substr(2, 9);
    return dataRepLegal;
  }

  desistir() {
    this.wizardService.resetData();
    this.router.navigate(['/index']);
  }

  voltar() {
    this.index = this.index - 1;
    this.wizardService.updateIndex(this.index);
    this.location.back();
  }

  mask(): any {
    return {
      mask: value => {
        value = value.replace(/[^\d]+/g, '');
        if (value.length <= 5) {
          return this.semMask;
        } else if (value.length <= 9) {
          return this.menasMask;
        } else if (value.length >= 10 && value.length <= 11) {
          return this.cpfMask;
        } else if (value.length >= 13 && value.length <= 16) {
          return this.cnpjMask;
        } else if (value.length <= 11) {
          return this.cpfMask;
        }
      },
      guide: false
    };
  }

  razaoSocialInvalid(){
    if(this.repLegalForm.controls["nomeRepresentanteLegal"].value.trim().length == 0) {
      this.repLegalForm.controls["nomeRepresentanteLegal"].setValue("");
    }
    if(this.outorgouForm.controls["nomeProcurador"].value.trim().length == 0){
      this.outorgouForm.controls["nomeProcurador"].setValue('');
    }
  }
  personalidadesJuridicasativos() {
    this.consultaUsuario.getPersonalidadesJuridicasAtivos().subscribe(
      res => {
        this.personalidadeJuridica = res.resultado;
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  setorAtuacaosativos() {
    this.consultaUsuario.getSetoresAtuacaoAtivos().subscribe(
      res => {
        this.setorAtuacao = res.resultado;
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  setLabels() {
    this.labels = {
      title: this.translate.instant('REPRESENTANTE.TITLE'),
      cnpj: this.translate.instant('GLOBAIS.CNPJ'),
      cpf: this.translate.instant('GLOBAIS.CPF'),
      nome: this.translate.instant('GLOBAIS.NOME'),
      razaoSocial: this.translate.instant('GLOBAIS.RAZAOSOCIAL'),
      procurador: this.translate.instant('GLOBAIS.PROCURADOR'),
      estado: this.translate.instant('GLOBAIS.ESTADO'),
      cidade: this.translate.instant('GLOBAIS.CIDADE'),
      logradouro: this.translate.instant('GLOBAIS.LOGRADOURO'),
      numero: this.translate.instant('GLOBAIS.NUMERO'),
      bairro: this.translate.instant('GLOBAIS.BAIRRO'),
      complemento: this.translate.instant('GLOBAIS.COMPLEMENTO'),
      telefone: this.translate.instant('GLOBAIS.TELEFONE'),
      comercial: this.translate.instant('GLOBAIS.COMERCIAL'),
      celular: this.translate.instant('GLOBAIS.CELULAR'),
      email: this.translate.instant('GLOBAIS.EMAIL'),
      nao: this.translate.instant('GLOBAIS.NAO'),
      sim: this.translate.instant('GLOBAIS.SIM'),
      anexoContratoSocial: this.translate.instant(
        'REPRESENTANTE.ANEXOCONTRATOSOCIAL'
      ),
      anexoCopiaProcuracao: this.translate.instant(
        'REPRESENTANTE.ANEXOCOPIAPROCURACAO'
      ),
      textRepresentante: this.translate.instant('REPRESENTANTE.TEXTREPRESENTANTE'),
      voltar: this.translate.instant('GLOBAIS.VOLTAR'),
      desistir: this.translate.instant('GLOBAIS.DESISTIR'),
      prosseguir: this.translate.instant('GLOBAIS.PROSSEGUIR')
    };
  }
}
