import { Component, OnInit, Input } from '@angular/core';
import { WizardService } from '../../../../service/wizard/wizard.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: '[app-wizard]',
  templateUrl: 'wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit {
  private _totalSteps: number;
  @Input()
  set totalSteps(value: number) {
    this.steps = [];
    for (let index = 0; index < value; index++) {
      this.steps.push('0' + (index + 1).toString());
    }
    this._totalSteps = value;
  }
  get totalSpes(): number {
    return this._totalSteps;
  }
  
  steps = [];
  subscrition: Subscription;
  
  active: number = 1;
  constructor(private wizardService: WizardService) {}
  
  ngOnInit() {
    this.wizardService.index$.subscribe(index => {
      this.active = index;
    });
    while (this.steps.length > 3) {
      this.steps.pop();
    }
  }
}
