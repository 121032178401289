import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AplicacoesService } from "../../../service/aplicacoes/aplicacoes.service";
import { CaixaPostalService } from "../../../service/caixa-postal/caixa-postal.service";
import { SessionService } from "../../../service/session/session.service";
import { UserService } from "../../../service/user-service/user.service";

import { TranslateService } from "@ngx-translate/core";
import { Usuario } from "../../../model/usuario";
import Swal from "sweetalert2";
import {getSwal} from "../../../utils/swalObject";
import swal from "sweetalert2";
import { MessagingService } from "src/app/service/push-notification/messaging.service";
import { InfoPerfilService } from "src/app/service/info-perfil/info-perfil.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  loading: boolean = true;
  exibirModalMensagens: boolean = false;
  mensagens: any = [];
  icones: Array<any> = [
    { name: "#cog", id: 0, selected: true },
    { name: "#megaphone", id: 1, selected: false },
    { name: "#monitor", id: 2, selected: false },
    { name: "#folder", id: 3, selected: false },
    { name: "#inbox", id: 4, selected: false },
    { name: "#world", id: 5, selected: false },
    { name: "#user", id: 6, selected: false },
    { name: "#mail", id: 7, selected: false },
    { name: "#tools", id: 8, selected: false },
    { name: "#file", id: 9, selected: false },
    { name: "#eye", id: 10, selected: true },
    { name: "#firewall", id: 11, selected: false },
    { name: "#flag", id: 12, selected: false },
    { name: "#info", id: 13, selected: false },
    { name: "#judge", id: 14, selected: false },
    { name: "#light-bulb", id: 16, selected: false },
    { name: "#molecule", id: 17, selected: false },
    { name: "#network", id: 18, selected: false },
    { name: "#notepad", id: 19, selected: false },
    { name: "#pie-chart", id: 20, selected: true },
    { name: "#open-book", id: 21, selected: false },
    { name: "#podium", id: 22, selected: false },
    { name: "#view", id: 23, selected: false },
    { name: "#telephone", id: 24, selected: false },
    { name: "#team", id: 25, selected: false },
    { name: "#shake-hands", id: 26, selected: false },
    { name: "#notebook", id: 27, selected: false },
    { name: "#coding", id: 28, selected: false },
    { name: "#coin", id: 29, selected: false },
    { name: "#clock", id: 31, selected: false },
    { name: "#upload", id: 33, selected: false },
    { name: "#thumb-up", id: 34, selected: false },
    { name: "#layers", id: 35, selected: false },
    { name: "#info", id: 36, selected: false },
    { name: "#pencil", id: 37, selected: false },
    { name: "#unlocked", id: 38, selected: false },
    { name: "#pie-chart", id: 39, selected: false },
    { name: "#worldwide", id: 40, selected: true },
    { name: "#people-connection", id: 41, selected: false },
    { name: "#children-charity", id: 42, selected: false },
    { name: "#arroba", id: 43, selected: false }
  ];

  labels: any;
  userInfo: object = {};
  mensagem: object = {};
  token = "";
  message;
  numDispositivo: number = 2;
  cpfCnpj: number;
  objetoSessao: any;
  notificationToken: string;

  private isSidebarClosed: boolean =
    localStorage.getItem("sidebar_is_closed") == "true";
  aplicacoes: Array<any> = [];

  constructor(
    private router: Router,
    private aplicacoesService: AplicacoesService,
    private sessionService: SessionService,
    private caixaPostalService: CaixaPostalService,
    private translate: TranslateService,
    private userService: UserService,
    private messagingService: MessagingService,
    private infoPerfilService: InfoPerfilService
  ) {
    this.setLabels();
    this.token = sessionService.getToken();
  }

  ngOnInit() {
    const token = localStorage.getItem("token");
    if (token) {
      this.isSidebarClosed = false;
    }
    this.obterDados();
    this.userInfo = this.sessionService.getSession(); 
    this.infoPerfilService.getUserState().subscribe((data) => {
      if (data) {
        this.userInfo = data;
      }
    });
  
    this.messagingService.requestPermission();
    this.carregarMensagensPopup();
  }

  obterDados() {
    this.aplicacoesService.obterAplicacaoComAcessoRestrito().subscribe(
      res => {
        this.loading = false;
        res["resultado"].forEach(element => {
          element.iconImage =
            element.icone === -1 ? "" : this.icones[element.icone];
        });

        this.aplicacoes = res["resultado"];

        const session = JSON.parse(localStorage.getItem("session"));

        this.aplicacoes.map(aplicacao => {
          if (session.perfilAcesso.codigo === 2) {
            aplicacao.url += this.encodeUrl({
              cpfCnpj: session.usuario.cpfCnpj,
              nomeRazaoSocial: session.usuario.nomeRazaoSocial,
              email: session.usuario.email,
              inscricaoMunicipal: session.usuario.detalhe.inscricaoMunicipal
            });
          } else if (session.perfilAcesso.codigo === 3) {
            aplicacao.url += this.encodeUrl({
              cpfCnpj: session.usuario.cpfCnpj,
              nomeRazaoSocial: session.usuario.nomeRazaoSocial,
              email: session.usuario.email,
              codigoProcurador: session.perfilAcesso.codigo,
              descricaoProcurador: session.perfilAcesso.descricao
            });
          }
        });
      },
      error => {
        this.loading = false;
      }
    );
  }

  marcarComoLido(codigo: number) {

    this.caixaPostalService.mensagemLida(codigo).subscribe(res => {
        if (res.resultado.length > 0) {
          this.carregarMensagensPopup();
        } else {
          this.exibirModalMensagens = false;
          this.loading = false;
          swal.fire(
            getSwal(
              'sucess',
              'Info!',
              'Confirmação de leitura realizada com Sucesso!',
              'ok',
              null
            )
          ).then(res => {});
        }
      },
      error => {
        this.loading = false;
      }
    );
    //this.exibirModalMensagens = false;
    // setTimeout(() => {
    //   this.carregarMensagensPopup();
    // }, 200);
    // this.loading = true;
  }

  closeModal() {
    this.exibirModalMensagens = false;
  }

  compareMessages(a, b) {
    if (new Date(a.dataEnvio).getTime() < new Date(b.dataEnvio).getTime()) {
      return -1;
    }
    if (new Date(a.dataEnvio).getTime() > new Date(b.dataEnvio).getTime()) {
      return 1;
    }
    return 0;
  }

  carregarMensagensPopup() {
    this.caixaPostalService.consultarPopUpMensagem().subscribe(
      res => {
        if (res.resultado.length > 0) {
          this.exibirModalMensagens = true;
          this.mensagens = res.resultado;
          this.mensagens.sort(this.compareMessages);
          this.mensagem = this.mensagens[0];
        } else {
          this.exibirModalMensagens = false;
          this.loading = false;
          //informação  mocada de alrta de popup
          // this.exibirModalMensagens = true;
          // this.mensagens = [{
          //   "protocolo":3119,"assunto":"TESTE COM CERTIFICADO ","dataEnvio":"2019-07-18T18:02:23",
          //   "temAnexo":false,"mensagem":"BOA NOITE, AJUSTAMOS O COMUNICADO COM CERTIFICADO",
          //   "tipoComunicado":{"codigo":91,"descricao":"COMUNICADO COM CERTIFICADO","dataCriacao":"2019-07-18T18:01:07","ativo":true,
          //   "contribuinteInicia":false,"contribuinteResponde":false,"utilizadoSistemasCorporativos":true,"notificaViaSms":false,
          //   "reenviaMensagemAposPrazo":false,"validade":0,"popupLeituraImpositiva":false,"exigeAssinaturaDigital":true,
          //   "enviaAlertaApp":false,"usoEmail":false,"auditorInicia":true,"objetivo":"COMUNICADO COM CERTIFICADO",
          //   "tipoPrazo":{"codigo":2,"descricao":"A partir da da leitura do Comunicado","dataCriacao":"2018-10-29T11:10:00","ativo":true}
          //   ,"cliente":{"codigo":1,"razaoSocial":"Controle de Qualidade","dataCriacao":"2018-07-27T12:01:11",
          //   "inicioContrato":"2018-08-13T00:00:00","fimContrato":"2025-12-31T00:00:00","ativo":true,"configuracao":null},
          //   "aplicacoes":null,"cor":{"codigo":2,"descricao":"Azul","cor":"#5882FA"},"usoInterno":false,"nomearAdvogado":true,
          //   "prazoCiencia":0},"situacaoMensagem":{"codigo":3418,"dataSituacao":"2019-07-22T10:44:04","situacao":1},
          //   "remetente":{"aplicacao":{"codigo":1,"descricao":"Plataforma DT-e","dataCriacao":"2018-08-24T10:51:26","ativo":true,
          //   "senha":null,"urlServicos":"http://10.0.1.115:8080/PlataformaDTe",
          //   "cliente":{"codigo":1,"razaoSocial":"Controle de Qualidade",
          //   "dataCriacao":"2018-07-27T12:01:11","inicioContrato":"2018-08-13T00:00:00","fimContrato":"2025-12-31T00:00:00",
          //   "ativo":true,"configuracao":null},"detalhe":null,"funcionalidades":null,"endpoints":null,
          //   "consumos":null,"dataInativacao":null,"usoInterno":true},"usuario":null,"procuracao":null},
          //   "destinatario":{"aplicacao":null,"usuario":{"codigo":100,"dataCriacao":"2019-01-11T15:28:06",
          //   "cpfCnpj":"92012618000190","tipoIdentificacao":2,"nomeRazaoSocial":"Giovanni e Rafael Eletrônica Ltda",
          //   "situacao":1,"dataInativacao":null,"email":"user@intranet.local","ddd":"11","celular":"974678306","detalhe":null}},
          //   "cienciaMensagem":[{"codigo":543,"dataCiencia":"2019-07-22T10:44:04","tacita":false,"leitura":null,
          //   "usuario":{"codigo":23,"dataCriacao":"2018-08-06T11:58:18","cpfCnpj":"33176236832",
          //   "tipoIdentificacao":1,"nomeRazaoSocial":"Ricardo Tsugumi","situacao":1,"dataInativacao":null,
          //   "email":"adriane.faverani@tecnogroup.com.br","ddd":"988092019","celular":"72","detalhe":{"codigo":316,"dataCriacao":"2019-07-23T11:29:06","ativo":true,"dataAlteracao":null,"cep":"06422100","logradouro":"rua teste teste","numero":"1154","complemento":"teste 11231321231","dddTelefoneComercial":"72","telefoneComercial":"77726733","emailComercial":"teste@teste.com.br","inscricaoMunicipal":null,"oab":null,"seccionalOab":null,"tipoOab":null,"situacaoOab":null}}}],"leitura":[{"codigo":542,"dataCiencia":"2019-07-22T10:44:04","tacita":false,"leitura":null,"usuario":{"codigo":23,"dataCriacao":"2018-08-06T11:58:18","cpfCnpj":"33176236832","tipoIdentificacao":1,"nomeRazaoSocial":"Ricardo Tsugumi","situacao":1,"dataInativacao":null,"email":"adriane.faverani@tecnogroup.com.br","ddd":"988092019","celular":"72","detalhe":{"codigo":316,"dataCriacao":"2019-07-23T11:29:06","ativo":true,"dataAlteracao":null,"cep":"06422100","logradouro":"rua teste teste","numero":"1154","complemento":"teste 11231321231","dddTelefoneComercial":"72","telefoneComercial":"77726733","emailComercial":"teste@teste.com.br","inscricaoMunicipal":null,"oab":null,"seccionalOab":null,"tipoOab":null,"situacaoOab":null}}}],"anexos":[],"respostas":[],"nomeacoes":[]}];
          // this.mensagem = this.mensagens[0];
        }
      },
      error => {
        this.loading = false;
      }
    );
  }

  registrarAcesso(codigoAplicacao: number, url: string) {
    url += "&token=" + this.userService.getUserData()["token"];
    window.open(url, "_blank");

    this.aplicacoesService.registrarAcesso(codigoAplicacao).subscribe(
      res => {
        console.log("resRegister", res);
      },
      error => {
        console.log("errRegister", error);
      }
    );
  }

  onSidebarToggle(isClosed: boolean) {
    this.isSidebarClosed = isClosed;
  }

  getColor(tipoComunicado) {
    if (tipoComunicado.cor !== null) {
      return tipoComunicado.cor.cor;
    } else {
      return "#a1a1a1";
    }
  }

  setLabels() {
    this.labels = {
      title: this.translate.instant("GLOBAIS.BEMVINDO"),
      acessar: this.translate.instant("GLOBAIS.ACESSAR"),
      desejaAcessar: this.translate.instant("HOME.DESEJAACESSAR"),
      cadastrar: this.translate.instant("GLOBAIS.CADASTRAR"),
      desistir: this.translate.instant("GLOBAIS.DESISTIR")
    };
  }

  encodeUrl = function(obj: any) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && obj[p]) {
        str.push(p + "=" + encodeURIComponent(obj[p]));
      }
    return "?" + str.join("&");
  };
}
